<template>
  <section>
    <div class="banner__footer">
      <img
          src="../../assets/icons/icon-lab.svg"
          alt="icon lab"
          title="icon lab"
          height="40px"
      />
      <p>
        Apoya tus ideas con la plataforma WydeaLab para madurarlas hasta
        convertirlas en un negocio con potencial.
      </p>
    </div>
  </section>
</template>

<script>
export default {
  name: "note",
};
</script>