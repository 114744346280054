<template>
  <section class="header border-bottom">
    <b-container>
      <b-row>
        <b-col cols="12" md="7">
          <div class="header__section">
            <div class="header__logo">
              <a href="/">
                <img
                  src="../../assets/logos/logo-color.svg"
                  alt="logo wydealab"
                  title="logo wydealab"
                />
              </a>

              <button class="header__btn" @click="showMenu()">
                <b-icon icon="list"></b-icon>
              </button>
            </div>
            <nav class="header__nav">
              <!--<a href="#methodology" rel="noopener noreferrer">Metodología</a>
              <a href="#plans" rel="noopener noreferrer">Precios</a>-->
              <b-dropdown id="products-dropdown" variant="white" text="Productos" class="btn-white bg-white border-0 m-md-2">
                <b-dropdown-item href="/" class="p-2">WydeaLab</b-dropdown-item>
                <b-dropdown-divider></b-dropdown-divider>
                <b-dropdown-item href="/scrapidt" class="p-2">Scrapidt</b-dropdown-item>
                <b-dropdown-divider></b-dropdown-divider>
              </b-dropdown>
              <b-dropdown id="services-dropdown" variant="white" text="Servicios" class="btn-white bg-white border-0 m-md-2">
                <b-dropdown-item href="/formulation" class="p-2">Formulación de Proyectos</b-dropdown-item>
              </b-dropdown>
              <a id="documentation-menu" href="/docs/" target="_blank" rel="noopener noreferrer">Documentación</a>
              <a href="https://medium.com/wydealab" target="_blank" rel="noopener noreferrer">Blog</a>
            </nav>
          </div>
        </b-col>
        <b-col cols="12" md="5">
          <div class="header__section">
            <!--<div class="translate" v-if="menuActive">
              <button class="btn-translate" v-on:click="showTranslate()">
                <b-icon icon="globe"></b-icon>
                <span v-show="language == 'en'">EN</span>
                <span v-show="language == 'es'">ES</span>
              </button>

              <div
                class="options-translate"
                v-show="stateTranslate"
                @mouseleave="closeTranslate()"
              >
                <a
                  @click="changeLanguage('en')"
                  href="#LANG-EN"
                  rel="noopener noreferrer"
                  >English</a
                >
                <a
                  @click="changeLanguage('es')"
                  href="#LANG-ES"
                  rel="noopener noreferrer"
                  >Español</a
                >
              </div>
            </div>-->
            <!-- <template v-if="info_usuario == null"> -->
              <a class="button mr-1" href="/signup/">Empezar</a>
              <a class="button_main" href="/signin/">Ya soy cliente</a>
            <!-- </template> -->
          </div>
        </b-col>
      </b-row>
    </b-container>
    <b-tooltip target="products-dropdown" triggers="hover">
      <b>Productos para simplificar tus procesos de innovaci&oacute;n</b>, en la nube y a bajo costo.
    </b-tooltip>

    <b-tooltip target="services-dropdown" triggers="hover">
      <b>Servicios para tercerizar la gesti&oacute;n de innovaci&oacute;n</b>, con expertos, herramietas y m&aacute;s.
    </b-tooltip>

    <b-tooltip target="documentation-menu" triggers="hover">
      <b>Contenidos para aclarar conceptos, si lo deseas.</b>, jerga de innovaci&oacute;n, negocios y startups.
    </b-tooltip>
  </section>
</template>

<script>
export default {
  name: "headerCustom",
  props: {
    menuActive: Boolean,
  },
  data: function () {
    return {
      language: "",

      info_usuario: null,

      stateTranslate: false,
    };
  },

  mounted() {
    let language = localStorage.getItem("userLanguage")
    if (language == null || language == "es") {
      this.language = "es"
      changeLanguage('es')
    } else {
      this.language = localStorage.getItem("userLanguage");
    }

    let userInfo = localStorage.getItem("userInfo");

    if (userInfo != null) {
      this.info_usuario = JSON.parse(localStorage.getItem("userInfo"));
      // this.loadNotifications();
    }
  },

  methods: {
    showMenu: function () {
      let header = document.getElementsByClassName('header')
      header[0].classList.toggle('header--show')
    },

    showTranslate: function () {
      this.stateTranslate = true;
    },

    closeTranslate: function () {
      this.stateTranslate = false;
    },

    changeLanguage: function (idioma) {
      localStorage.setItem("userLanguage", idioma);

      this.language = idioma;

      Transifex.live.translateTo(idioma, true);

      this.closeTranslate();
    },
  },
};
</script>

<style lang="scss">
* {
  scroll-behavior: smooth;
}

.header {
  height: 100px;
  background-color: rgba($color: white, $alpha: 0.95);
  position: sticky;
  top: 0;
  z-index: 40;
  overflow: hidden;

  &__section {
  }

  &__btn {
    width: 35px;
    min-width: 35px;
    height: 35px;
    margin-left: auto;
    border: 2px solid #171e44;
    border-radius: 5px;
    background: transparent;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      font-size: 25px;
      color: #171e44;
    }
  }

  &__logo {
    margin: 0 0 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    a {
      width: 100%;
      max-width: 210px;
      padding: 20px 25px;
      border-radius: 0px 0px 10px 10px;
    }

    img {
      width: 100%;
      max-width: 125px;
      margin: 0 auto;
      display: block;
    }
  }

  &__nav {
    padding: 10px 0;
    a {
      margin: 0;
      padding: 5px 0;
      font-size: 16px;
      letter-spacing: 1px;
      font-weight: normal;
      color: #333333;
      text-align: center;
      display: block;
      &:hover {
        text-decoration: none;
        color: #006df0;
      }
    }
  }

  &--show {
    height: auto;
  }

  // responsive para dispositivos de tablets en adelante
  @media (min-width: 992px) {
    height: auto;
    overflow: inherit;

    &__logo {
      margin: 0;
    }

    &__btn {
      display: none;
    }

    &__nav {
      padding: 0 10px;
      display: flex;
      align-items: center;

      a {
        padding: 0 10px;
      }
    }
    &__section {
      display: flex;
      align-items: center;
      height: 100%;
    }
  }
}

.translate {
  margin: 20px auto;
  position: relative;

  &.show {
    .btn-translate {
      svg {
        color: #ff8028;
      }
    }
  }
  &.show {
    .options-translate {
      height: auto;
    }
  }

  // responsive para dispositivos de tablets en adelante
  @media (min-width: 992px) {
    margin: 0 20px 0 auto;
  }
}

.btn-translate {
  width: 30px;
  min-width: 30px;
  height: 30px;
  margin: 0 auto;
  border: 0;
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  svg {
    font-size: 20px;
    color: #384c74;
    pointer-events: none;
  }

  span {
    padding: 0 4px;
    border-radius: 30px;
    background-color: white;
    font-family: "Mulish", sans-serif;
    font-size: 10px;
    font-weight: bold;
    color: #384c74;
    text-align: center;
    text-transform: uppercase;
    position: absolute;
    top: 15px;
    left: 15px;
  }

  // responsive para dispositivos de tablets en adelante
  @media (min-width: 992px) {
    margin: 0;
  }
}

.options-translate {
  a {
    margin: 20px 0;
    font-family: "Quicksand", sans-serif;
    font-size: 14px;
    font-weight: normal;
    color: #4d556e;
    text-align: center;
    display: block;
  }

  // responsive para dispositivos de tablets en adelante
  @media (min-width: 992px) {
    min-width: 100px;
    max-width: 150px;
    border-radius: 5px;
    background-color: #ffffff;
    box-shadow: 0px 3px 6px #4d576f29;
    position: absolute;
    top: 30px;
    right: 0;
    overflow: hidden;

    a {
      margin: 0;
      padding: 5px;
      font-family: "Quicksand", sans-serif;
      font-size: 14px;
      font-weight: normal;
      color: #4d556e;
      text-align: center;
      display: block;

      &:hover {
        text-decoration: none;
        background-color: #e9ecf4;
      }
    }
  }
}
</style>
