<template>
  <section class="benefits">
    <b-container fluid="lg">
      <b-row>
        <b-col cols="12" md="6">
          <div class="benefits__content">
            <div>
              <h2 class="benefits__title">
                Beneficios con <strong>WydeaLab</strong>
              </h2>
              <ul>
                <li>
                  <img src="../../assets/icons/icon-list.svg" alt="icon list" title="icon list">
                  <p class="benefits__paragraph">Simplifica los procesos de innovación para hacerlos
                  recurrentes y relevantes dentro del negocio.</p>
                </li>
                <li>
                  <img src="../../assets/icons/icon-list.svg" alt="icon list" title="icon list">
                  <p class="benefits__paragraph">Permite evaluar ideas y convertir ideas potenciales en nuevos
                  productos, servicios o empresas con enfoques claros y bien
                  soportados.</p>
                </li>
                <li>
                  <img src="../../assets/icons/icon-list.svg" alt="icon list" title="icon list">
                  <p class="benefits__paragraph">Establece un canal para que los miembros del equipo compartan
                  e interactúen en pro de generar innovación, sofisticación e
                  incremento de potencial de negocio.</p>
                </li>
                <li>
                  <img src="../../assets/icons/icon-list.svg" alt="icon list" title="icon list">
                  <p class="benefits__paragraph">Facilita el análisis de problemáticas para convertirlas en
                  oportunidades.</p>
                </li>
                <li>
                  <img src="../../assets/icons/icon-list.svg" alt="icon list" title="icon list">
                  <p class="benefits__paragraph">Válida la factibilidad de tus ideas antes de realizar
                  cualquier inversión.</p>
                </li>
              </ul>
            </div>
          </div>
        </b-col>
        <b-col cols="12" md="6">
          <div class="dream__img">
            <img
              src="../../assets/images/img-beneficios.png"
              alt="image beneficios"
              title="image beneficios"
            />
          </div>
        </b-col>
      </b-row>
    </b-container>
  </section>
</template>

<script>
export default {
  name: "benefits",
};
</script>

<style lang="scss">
.benefits {
  padding: 50px 0;
  width: 100%;
  background-color:#ffc107;

  &__img {
    margin: 0 0 45px;

    img {
      width: 100%;
      display: block;
    }
  }

  &__title {
    margin: 0 0 30px;
    font-family: "Quicksand", sans-serif;
    font-size: 30px;
    font-weight: 500;
    color: #171e44;
    text-align: center;
  }

  &__paragraph {
    margin: 0 0 30px;
    font-family: "Mulish", sans-serif;
    font-size: 18px;
    font-weight: 500;
    color: #171e44;
  }

  ul {
    padding: 0;
    list-style: none;

    li {
      display: flex;
      align-items: flex-start;

      img {
        margin: 5px 35px 0 0;
        display: block;
      }
    }
  }

  // responsive para dispositivos de tablets en adelante
  @media (min-width: 992px) {
    padding: 80px 0;

    &__title,
    &__paragraph {
      text-align: left;
    }
  }
}
</style>
