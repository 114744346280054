<template>
  <section class="dream" id="what">
    <b-container fluid="lg">
      <b-row>
        <b-col cols="12" md="6">
          <div class="dream__img mt-5">
            <img src="@/assets/images/formulation/0.png" class="full-width pt-5">
          </div>
        </b-col>
        <b-col cols="12" md="6">
          <div class="dream__content">
            <div>
              <h2 class="dream__title">¿Qué es?</h2>
              <p class="dream__paragraph">Formular proyectos transformar una idea en un plan para <strong>tener claridad
                del alcance, el costos, el tiempo y la calidad</strong> esperada de un producto, servicio, empresa,
                infraestructura u otro.
              </p>
              <p class="dream__paragraph">
                <b>
                  Contamos con experiencia definiendo y evaluando proyectos lo que nos da una
                  perspectiva clara de lo que se debe incluir para dar claridad de lo que se desea lograr y de igual
                  manera mitigar los riesgos que todo proyecto tiene.
                </b>
              </p>

              <p class="dream__paragraph"><b>El PMI (Project Management Institute</b> - <a href="https://pmi.org">https://pmi.org</a>) uno de los organizamos
                de gestión de proyectos más reconocido del mundo define un proyecto como: <em>un emprendimiento temporal
                  que se lleva a cabo para crear un producto o servicio. Es un proceso, con una duración determinada y
                  un fin concreto, compuesto por actividades y tareas diferentes, que puede ser elaborado de manera
                  gradual.</em> <b>Cada proyecto tiene sus particularidades y realmente no existe un proyecto exactamente
                  igual a otro, de ahí la importancia de la formulación de proyectos y de los formuladores expertos</b>.
              </p>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </section>
</template>

<script>
export default {
  name: "dream",
  data: function () {
    return {
      item: 1,
    };
  },
  mounted() {
    this.autoChangeItem();
  },
  methods: {

  }
};
</script>

<style lang="scss">
.dream {
  padding: 50px 0;
  width: 100%;
  background: #fefefe;

  &__img {
    margin: 0 0 45px;

    img {
      width: 100%;
      display: block;
    }
  }

  &__options {
    button {
      width: 9px;
      min-width: 9px;
      height: 9px;
      padding: 0;
      margin: 0 5px;
      border: 0;
      border-radius: 50%;
      background-color: #aaaaaa;

      &.active {
        background-color: #171e44;;
      }
    }
  }

  &__title {
    margin: 0 0 30px;
    font-family: "Quicksand", sans-serif;
    font-size: 30px;
    font-weight: 600;
    color: #171e44;
    text-align: center;
  }

  &__paragraph {
    margin: 0 0 30px;
    font-family: "Mulish", sans-serif;
    font-size: 18px;
    font-weight: 500;
    color: #171e44;
    text-align: justify;
  }

  // responsive para dispositivos de tablets en adelante
  @media (min-width: 992px) {
    padding: 80px 0;

    &__title,
    &__paragraph {
      text-align: left;
    }

    &__content {
      padding: 20px 0;
    }
  }
}
</style>
