<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
export default {
  name: 'app',
  metaInfo: {
    title: 'WydeaLab: Plataforma para gestionar e impulsar tu innovación y emprendimiento.',
    meta: [
      { name: 'title', content: 'WydeaLab: Software para gestionar e impulsar tu innovación.' },
      { name: 'description', content: 'WydeaLab consolida diferentes herramientas para ayudar a empresas y emprendedores a identificar ideas, priorizarlas, madurarlas para así impulsar la creaci&oacute;n negocios disruptivos, diferentes y con un enfoque en la agilidad y la rentabilidad.' },
      { name: 'keywords', content: 'Innovación, Innovación Bucaramanga, WydeaLab, Emprendimiento, ' +
            'Priorización de Ideas, Maduración de Ideas,  Gestión de Innovación, Iniciar Emprendimiento,' +
            ' Modelos de Negocio, Modelo Canvas, Presupuesto Para Innovar, ' +
            'Software para Innovar, Gestores de Innovación, Plataforma para innovar, Innovación Bucaramanga,' +
            'Plataforma para modelar negocios, Priorización de Ideas, Maduración de Ideas, Evaluación de Ideas,' +
            'Evaluación de emprendimientos, Software para comites de emprendimientos, ' },
      { name: 'Language', content: 'Spanish' }
    ]
  }
}
</script>
