<template>
  <section class="questions">
    <b-container fluid="lg">
      <b-row>
        <b-col>
          <h3 class="questions__title">Preguntas Frecuentes</h3>
          <div class="questions__section">
            <section>
              <button v-b-toggle.questions-1>¿Por qué utilizar la plataforma de WydeaLab?
                <img src="../../assets/icons/icon-arrow.svg" alt="icon arrow" title="icon arrow">
              </button>
              <b-collapse id="questions-1">
                <p class="questions__paragraph">WydeaLab simplifica la gestión de innovación para tí y para tu equipo, aplica una metodología en cada uno de los módulos para hacer sencillo el mejoramiento continuo basado en innovación.</p>
              </b-collapse>
            </section>
            <section>
              <button v-b-toggle.questions-2>¿Por qué invertir en una plataforma para gestionar la innovación?
                <img src="../../assets/icons/icon-arrow.svg" alt="icon arrow" title="icon arrow">
              </button>
              <b-collapse id="questions-2">
                <p class="questions__paragraph">La innovación es hoy por hoy el motor de crecimiento de las empresas, es algo que debe existir en el ADN y que abre nuestra mente para evitar la resistencia al cambio. Es una forma sencilla para mantener a nuestro equipo de trabajo en hacerlo siempre mejor.</p>
              </b-collapse>
            </section>
            <section>
              <button v-b-toggle.questions-3>¿Puedo utilizar WydeaLab para impulsar mi emprendimiento?
                <img src="../../assets/icons/icon-arrow.svg" alt="icon arrow" title="icon arrow">
              </button>
              <b-collapse id="questions-3">
                <p class="questions__paragraph">Ayudarte a madurar tu idea o ideas, priorizando y ayudándote a establecer acciones que te den claridad gradual de tu meta te permitirá incrementar el potencial de éxito.</p>
              </b-collapse>
            </section>
            <section>
              <button v-b-toggle.questions-4>¿Cómo puedo empezar a consolidar mi idea?
                <img src="../../assets/icons/icon-arrow.svg" alt="icon arrow" title="icon arrow">
              </button>
              <b-collapse id="questions-4">
                <p class="questions__paragraph">Solo debes abrir una cuenta e iniciar paso a paso, debes aprovechar cada módulo para registrar, analizar y gestionar la innovación permanente en tu emprendimiento.</p>
              </b-collapse>
            </section>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </section>
</template>

<script>
export default {
  name: 'questions'
}
</script>

<style lang="scss">
.questions {
  width: 100%;
  padding: 80px 0;
  background-color: #21A1FF;

  &__title {
    margin: 0 0 40px;
    font-family: 'Quicksand', sans-serif;;
    font-size: 30px;
    font-weight: 600;
    color: white;
    text-align: center;
  }

  &__paragraph {
    margin: 0;
    font-family: 'Mulish', sans-serif;;
    font-size: 16px;
    font-weight: 500;
    color: #2C3255;
    text-align: left;
  }

  &__section {
    border-radius: 10px;
    background-color: white;
    text-align: center;

    button {
      width: 100%;
      border: 0;
      padding: 0;
      background: transparent;
      font-family: 'Mulish', sans-serif;;
      font-size: 20px;
      font-weight: 600;
      color: #171E44;
      display: flex;
      align-items: center;

      img {
        margin-left: auto;
        transition: all 0.5s;
      }

      &.not-collapsed {
        margin: 0 0 30px;

        img {
          transform: rotate(180deg);
        }
      }
    }

    section {
      border-bottom: 1px solid #C4C9D6;
      padding: 40px;
    }

    & > :last-child {
      border: 0;
    }
  }

  // responsive para dispositivos de tablets en adelante
  @media (min-width: 992px) {
    &__title {
      text-align: left;
    }
  }
}
</style>
