
<template>
  <footer class="footer">
    <b-container>
      <b-row>
        <b-col cols="12" md="3">
          <img
            class="footer__logo"
            src="../../assets/logos/logo.svg"
            alt="logo wydealab"
            title="logo wydealab"
          />
          <nav class="footer__nav">
            <a href="https://www.facebook.com/wydealab" target="_blank" rel="noopener noreferrer">
              <img
                src="../../assets/icons/icon-facebook.svg"
                alt="icon facebook"
                title="icon facebook"
              />
            </a>
            <a href="https://twitter.com/wydealab" target="_blank" rel="noopener noreferrer">
              <img
                src="../../assets/icons/icon-twitter.svg"
                alt="icon twitter"
                title="icon twitter"
              />
            </a>
            <a href="https://www.instagram.com/wydealab/" target="_blank" rel="noopener noreferrer">
              <img
                src="../../assets/icons/icon-instagram.svg"
                alt="icon instagram"
                title="icon instagram"
              />
            </a>
            <a href="https://www.linkedin.com/company/wydealab" target="_blank" rel="noopener noreferrer">
              <img
                src="../../assets/icons/icon-linkend.svg"
                alt="icon linkend"
                title="icon linkend"
              />
            </a>
            <a href="https://medium.com/wydealab" target="_blank" rel="noopener noreferrer">
              <img
                src="../../assets/icons/icon-blog.svg"
                alt="icon blog"
                title="icon blog"
              />
            </a>
          </nav>
        </b-col>
        <b-col cols="12" md="3">
          <h3 class="footer__subtitle">Acerca de</h3>
          <a
            class="footer__link"
            href="#about"
            rel="noopener noreferrer"
            >Nosotros</a
          >
          <a
            class="footer__link"
            href="#methodology"
            rel="noopener noreferrer"
            >Metodología</a
          >
          <a
            class="footer__link"
            href="/help/"
            target="_blank"
            rel="noopener noreferrer"
            >Centro de ayuda</a
          >
        </b-col>
        <b-col cols="12" md="3">
          <h3 class="footer__subtitle">Productos</h3>
          <a
              class="footer__link"
              href="/"
              rel="noopener noreferrer"
          >WydeaLab</a
          >
          <a
              class="footer__link"
              href="/scrapidt"
              rel="noopener noreferrer"
          >Scrapidt</a
          >
        </b-col>
        <b-col cols="12" md="3">
          <h3 class="footer__subtitle">Comunicación</h3>
          <a
            class="footer__link"
            href="mailto:hello@wydealab.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src="../../assets/icons/icon-email-2.svg"
              alt="icon email"
              title="icon email"
            />
            hello@wydealab.com
          </a>
          <a
            class="footer__link"
            href="https://wa.me/573052328630"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src="../../assets/icons/icon-whatsap.svg"
              alt="icon whatsap"
              title="icon whatsap"
            />
            +57 (305) 232-8630
          </a>
          <a
            class="footer__link"
            href="https://medium.com/wydealab"
            target="_blank"
            rel="noopener noreferrer"
            >Blog</a
          >
        </b-col>
      </b-row>
      <b-row class="footer__line">
        <b-col cols="12" md="6">
          <p>© {{ fecha }} <strong>WydeaLab</strong></p>
        </b-col>
        <b-col cols="12" md="6">
          <a href="/privacy_policy/">Legal</a>
        </b-col>
      </b-row>
    </b-container>
  </footer>
</template>

<script>
export default {
  name: "footerCustom",
  data: function () {
    return {
      fecha: "",
    };
  },
  mounted() {
    this.fecha = new Date().getFullYear();
  },
};
</script>

<style lang="scss">
.footer {
  padding: 50px 0;
  width: 100%;
  background-color: #171e44;

  &__logo {
    margin: 0 auto 50px;
    display: block;
  }

  &__nav {
    margin: 0 0 50px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;

    a{
      width: 25px;
      min-width: 25px;
      height: 25px;
      margin: 0 10px 0 0;
      border-radius: 50%;
      display: inline-flex;
      align-items: center;
      justify-content: center;
    }
  }

  &__subtitle {
    margin: 0 0 30px;
    font-family: "Quicksand", sans-serif;
    font-size: 18px;
    font-weight: 500;
    color: #fffe60;
    text-align: center;
  }

  &__link {
    margin: 0 0 20px;
    font-family: "Mulish", sans-serif;
    font-size: 16px;
    font-weight: normal;
    color: white;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      margin: 0 10px 0 0;
      display: block;
    }
  }
  &__link:hover {
    color: white;
  }

  &__line {
    border-top: 0.1rem solid #7d8aa7;
    margin: 30px 0 0;
    padding: 30px 0 0;

    p,
    a {
      font-family: "Quicksand", sans-serif;
      font-size: 14px;
      font-weight: normal;
      color: white;
      text-align: center;
      display: block;
    }
    a:hover{
      color:#fefefe;
    }
  }

  // responsive para dispositivos de tablets en adelante
  @media (min-width: 992px) {
    padding: 90px 0 110px;

    &__logo {
      margin: 0 0 40px;
    }

    &__nav {
      justify-content: flex-start;
    }

    &__subtitle,
    &__link {
      text-align: left;
      justify-content: flex-start;
    }

    &__line {
      p {
        text-align: left;
      }

      a {
        text-align: right;
      }
    }
  }
}
</style>
