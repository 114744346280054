<template>
  <section class="modules bg-warning" id="modules">
    <h2>¿Qué módulos tiene?</h2>
    <b-container fluid="lg">
      <b-card-group columns >
        <b-card v-for="module in modules" v-bind:key="module.id" bg-variant="white" text-variant="dark"
                :header="module.label"
                tag="module"
                style="max-width: 20rem;"
                class="mb-2 text-center"
                header-text-variant="white"
        >
          <b-card-text>{{module.description}}</b-card-text>
        </b-card>
      </b-card-group>
      <p class="text-center text-dark mt-3" style="font-size:1.4em">
        <b>Tienes la oportunidad de opinar, solicitar y recomendar mejoras</b> utilizando nuestra herramienta
        integrada de feedback disponible a través de toda la plataforma. Cada semanas tenemos algo nuevo para apoyar la gestión de innovación.
      </p>
    </b-container>
    <div class="text-center">
      <a class="button_secondary mt-5" href="/signup/">Empezar</a>
      <small>Organizar y gestionar tus ideas es tan simple como dar un click.</small>
    </div>
  </section>
</template>

<script>
export default {
  name: "modules",
  data: function(){
    return {
      modules: [
        { id: 1,
          label:"Gestor de Ideas",
          description:"Define, madura y evalúa ideas para generar impacto y crecimiento en tus productos y servicios.",
          image:"https://cdn.wydealab.com/wydealab/website/wydea-module-idea.png"
        },
        { id: 2,
          label:"Planeador Estratégico",
          description:"Define tu plan estratégico de innovación de manera concisa y directa.",
          image:"https://cdn.wydealab.com/wydealab/website/wydea-module-estrategia.png"
        },
        { id: 3,
          label:"Árbol de Problemáticas",
          description:"Identifica causas y efectos y propon soluciones para cambiar el status-quo de tu negocio.",
          image:"https://cdn.wydealab.com/wydealab/website/wydea-module-problemas.png"
        },
        { id: 4,
          label:"Modelador de Negocios",
          description:"Crea tu modelo de negocios analizando 9 contextos y genera un presupuesto claro.",
          image:"https://cdn.wydealab.com/wydealab/website/wydea-module-negocio.png"
        },
        { id: 5,
          label:"Formulador",
          description:"Formula planes de negocio y proyectos para transformar tu idea en un paso a paso.",
          image:"https://cdn.wydealab.com/wydealab/website/wydea-module-formulador.png"
        },
        { id: 6,
          label:"Hitos y Metas",
          description:"Establece y programa avances para medir la evolución de tu proyecto innovador.",
          image:"https://cdn.wydealab.com/wydealab/website/wydea-module-meta.png"
        }
      ]
    }
  }
};
</script>

<style lang="scss" scoped>
  .modules{
    background-color: #f8f8f8;
    border-top:2px solid #dddddd;
    border-bottom:2px solid #dddddd;
    padding-top:2em;
    padding-bottom:2em;
  }
  h2{
    color:#1b2c66;
    margin: 0 0 30px;
    font-family: "Quicksand", sans-serif;
    font-size: 48px;
    font-weight: 500;
    text-align: center;
  }
  .card-header{
    font-weight: bold;
    background: #1b2c66;
    color:#fefefe;
  }
  .card{
    border:5px solid #fefefe;
    margin-bottom: 3em;
  }
</style>
