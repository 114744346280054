import { render, staticRenderFns } from "./navService.vue?vue&type=template&id=2094bcbe&scoped=true"
import script from "./navService.vue?vue&type=script&lang=js"
export * from "./navService.vue?vue&type=script&lang=js"
import style0 from "./navService.vue?vue&type=style&index=0&id=2094bcbe&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2094bcbe",
  null
  
)

export default component.exports