<template>
  <section>
    <div class="banner__footer">
      <img
          src="../../assets/icons/icon-target-dark.svg"
          alt="icon lab"
          title="icon lab"
          height="40px"
      />
      <p>
        Convierte tus ideas en proyectos utilizando nuestra <b>experiencia conjunta de mas de 30 años formulando proyectos &eacute;xitosos</b>.
      </p>
    </div>
  </section>
</template>

<script>
  export default {
    name: 'note'
  }
</script>