<template>
  <section class="banner">
    <b-container fluid="lg">
      <b-row>
        <b-col cols="12" md="7">
          <div class="banner__img">
            <img
              src="../../assets/images/img-metodologia-3.jpg"
              alt="image banner"
              title="image banner"
            />
          </div>
        </b-col>
        <b-col cols="12" md="5">
          <div class="banner__content">
            <div>
              <h2 class="banner__title">Aprendamos juntos a innovar.</h2>
              <p class="banner__paragraph">
                <strong>WydeaLab</strong> te ayuda a entender como innovar solo o con tu equipo para convertir esas ideas que rondan tu cabeza en una realidad.
              </p>
              <a class="banner__button" href="#methodology" rel="noopener noreferrer"
                >Saber más</a
              >
            </div>
          </div>
        </b-col>
      </b-row>
    </b-container>
    <div class="banner__footer">
      <img
        src="../../assets/icons/icon-lab.svg"
        alt="icon lab"
        title="icon lab"
      />
      <p>
        Apoya tus ideas con la plataforma WydeaLab para madurarlas hasta
        convertirlas en un negocio con potencial.
      </p>
    </div>
  </section>
</template>

<script>
export default {
  name: "banner",
};
</script>

<style lang="scss">
.banner {
  padding: 50px 0 0;
  width: 100%;
  background-color: white;

  &__img {
    margin: 0 0 45px;

    img {
      width: 100%;
      display: block;
    }
  }

  &__title {
    margin: 0 0 30px;
    font-family: "Quicksand", sans-serif;
    font-size: 48px;
    font-weight: bold;
    color: #171e44;
    text-align: center;
  }

  &__paragraph {
    margin: 0 0 30px;
    font-family: "Mulish", sans-serif;
    font-size: 18px;
    font-weight: 500;
    color: #171e44;
    text-align: center;
  }

  &__button {
    width: 100%;
    height: 40px;
    margin: 0x;
    padding: 0 10px;
    border: 2px solid #171e44;
    border-radius: 32px;
    background-color: #fffe60;
    font-family: "Quicksand", sans-serif;
    font-size: 16px;
    font-weight: 500;
    color: #171e44;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__footer {
    margin: 30px 0 0;
    padding: 30px 15px;
    background-color: #fffe60;

    img {
      margin: 0 auto 30px;
      display: block;
    }

    p {
      margin: 0;
      font-family: "Mulish", sans-serif;
      font-size: 18px;
      font-weight: 500;
      color: #171e44;
      text-align: center;
    }
  }

  // responsive para dispositivos de tablets en adelante
  @media (min-width: 992px) {
    padding: 30px 0 0;

    &__title,
    &__paragraph {
      text-align: left;
    }

    &__button {
      max-width: 140px;
    }

    &__content {
      height: 100%;
      display: flex;
      align-items: center;
    }

    &__footer {
      margin: 0;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        margin: 0 30px 0 0;
      }

      p {
        max-width: 540px;
        text-align: left;
      }
    }
  }
}
</style>
