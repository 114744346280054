<template>
  <section class="methodology" id="how">
    <b-container fluid="lg">
      <b-row>
        <b-col>
          <h2 class="methodology__title">¿Cómo funciona?</h2>
          <p class="methodology__paragraph">
            Te explicamos de manera sencilla lo que hacemos para obtener y notificarte oportunidades clave
            para tu negocio.
          </p>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" md="6">
          <div class="methodology__item">
            <h3>
              <img
                src="../../assets/icons/icon-number-1.svg"
                alt="icon number 1"
                title="icon number 1"
              />
              Identificar entidades, grupos y fuentes.
            </h3>
            <section>
              <img
                src="../../assets/icons/icon-metodologia-1.svg"
                alt="icon methodology 1"
                title="icon methodology 1"
              />
              <p>
                Todas las semanas nuestro equipo cuenta con una reunión en donde proponemos 10 nuevos
                orígenes para monitorear.
              </p>
            </section>
          </div>
        </b-col>
        <b-col cols="12" md="6">
          <div class="methodology__img">
            <img
              src="../../assets/images/scrapidt/1.jpg"
              alt="images methodology 1"
              title="images methodology 1"
            />
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" md="6" order="2" order-md="1">
          <div class="methodology__img">
            <img
              src="../../assets/images/scrapidt/2.jpg"
              alt="images methodology 2"
              title="images methodology 2"
            />
          </div>
        </b-col>
        <b-col cols="12" md="6" order="1" order-md="2">
          <div class="methodology__item">
            <h3>
              <img
                src="../../assets/icons/icon-number-2.svg"
                alt="icon number 2"
                title="icon number 2"
              />
              Priorizando el listado.
            </h3>
            <section>
              <img
                src="../../assets/icons/icon-metodologia-2.svg"
                alt="icon methodology 2"
                title="icon methodology 2"
              />
              <p>
                Se remite el listado priorizado a nuestro equipo técnico que lo valida y genera un listado de 5 orígenes y se
                asigna un día de la semana a cada uno para hacerlo.
              </p>
            </section>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" md="6">
          <div class="methodology__item">
            <h3>
              <img
                src="../../assets/icons/icon-number-3.svg"
                alt="icon number 3"
                title="icon number 3"
              />
              Implementación
            </h3>
            <section>
              <img
                src="../../assets/icons/icon-metodologia-3.svg"
                alt="icon methodology 3"
                title="icon methodology 3"
              />
              <p>
                Se le da vida a un bot que monitorea el origen cada 24 horas, dándonos acceso a la información para
                realizar procesos de analítica de datos que genera un listado optimizado de opciones que son
                registradas de manera ordenada en nuestra base de datos central.
              </p>
            </section>
          </div>
        </b-col>
        <b-col cols="12" md="6">
          <div class="methodology__img">
            <img
              src="../../assets/images/scrapidt/3.jpg"
              alt="images methodology 3"
              title="images methodology 3"
            />
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" md="6" order="2" order-md="1">
          <div class="methodology__img">
            <img
              src="../../assets/images/scrapidt/4.jpg"
              alt="images methodology 4"
              title="images methodology 4"
            />
          </div>
        </b-col>
        <b-col cols="12" md="6" order="1" order-md="2">
          <div class="methodology__item">
            <h3>
              <img
                src="../../assets/icons/icon-number-4.svg"
                alt="icon number 4"
                title="icon number 4"
              />
              Afinamiento del modelo de análisis.
            </h3>
            <section>
              <img
                src="../../assets/icons/icon-metodologia-4.svg"
                alt="icon methodology 4"
                title="icon methodology 4"
              />
              <p>
                El bot queda en prueba por 5 días para validar sus resultados, para lo cuál nuestro equipo analiza
                sus respuestas y afina el modelo.
              </p>
            </section>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" md="6">
          <div class="methodology__item">
            <h3>
              <img
                src="../../assets/icons/icon-number-5.svg"
                alt="icon number 5"
                title="icon number 5"
              />
              Aprobación y liberación.
            </h3>
            <section>
              <img
                src="../../assets/icons/icon-target.svg"
                alt="icon methodology 5"
                title="icon methodology 5"
              />
              <p>
                Se activa el bot para se una a los otros bots y realicen diariamente análisis de decenas de origenes
                para generarte alertas cuando aparezca algo nuevo. Es genial ¿No?.
              </p>
            </section>
          </div>
        </b-col>
        <b-col cols="12" md="6">
          <div class="methodology__img m-0">
            <img
              src="../../assets/images/scrapidt/5.jpg"
              alt="images methodology 5"
              title="images methodology 5"
            />
          </div>
        </b-col>
      </b-row>
    </b-container>
  </section>
</template>

<script>
export default {
  name: 'methodology'
}
</script>

<style lang="scss">
.methodology {
  padding: 50px 0;
  width: 100%;
  background-color: #fefefe;

  &__title {
    margin: 0 0 30px;
    font-family: "Quicksand", sans-serif;
    font-size: 48px;
    font-weight: 500;
    color: #21a1ff;
    text-align: center;
  }

  &__paragraph {
    margin: 0 0 30px;
    font-family: "Mulish", sans-serif;
    font-size: 24px;
    font-weight: 500;
    color: #171e44;
    text-align: center;
  }

  &__item {
    h3 {
      margin: 0 0 30px;
      font-family: "Quicksand", sans-serif;
      font-size: 36px;
      font-weight: 500;
      color: #171e44;
      display: flex;
      align-items: center;

      img {
        margin: 0 20px 0 0;
        display: block;
      }
    }

    section {
      width: 100%;
      padding: 30px;
      border-radius: 10px;
      background-color: #1b2c66;

      img {
        margin: 0 auto 30px;
        display: block;
      }

      p {
        margin: 0 0 30px;
        font-family: "Mulish", sans-serif;
        font-size: 20px;
        font-weight: 500;
        color: white;
        text-align: center;
      }
    }
  }

  &__img {
    margin: 50px 0 100px;

    img {
      width: 100%;
      display: block;
    }
  }

  // responsive para dispositivos de tablets en adelante
  @media (min-width: 992px) {
    padding: 80px 0;

    &__paragraph {
      max-width: 590px;
      margin: 0 auto 90px;
    }

    &__img {
      margin: 0 0 100px;

      img {
        width: auto;
        height: 375px;
      }
    }
  }
}
</style>
