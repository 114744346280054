<template>
  <section class="stats" id="stats">
    <h2 class="text-center p-3">Seguimos avanzando</h2>
    <b-row>
      <b-col class="text-center stat-item" v-for="stat in stats" v-bind:key="stat.id">
        <div>{{ stat.value }}</div>
        <small>{{stat.label}}</small>
      </b-col>
    </b-row>
  </section>
</template>

<script>
export default {
  name: 'stats',
  data: function () {
    return {
      stats: [
        { id: 1, label: 'Proyectos Formulados', value: '45' },
        { id: 2, label: 'Clientes Atendidos', value: '12' },
        { id: 3, label: 'Sectores', value: '5' },
        { id: 4, label: 'Formuladores', value: '4' }
      ]
    }
  }
}

</script>
<style lang="scss" scoped>
.stats{
  background-color: #ffc107;
  padding:1em;
}
h2{
  margin: 0 0 30px;
  font-family: "Quicksand", sans-serif;
  font-size: 48px;
  font-weight: 500;
  color: #171E44;
  text-align: center;
}
.stat-item{
  border:2px solid #f8f8f8;
  margin:2em;
  border-radius: 0.5em;
  padding:5px;
  background-color: #fefefe;
  div{
    font-size: 40px;
    color:#21a1ff;
  }
  small{
    font-size: 20px;
  }
}
</style>