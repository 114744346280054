<template>
  <section class="plans" id="plans">
    <b-container>
      <b-row>
        <b-col cols="12" md=""></b-col>
        <b-col cols="12" md="6">
          <h2 class="plans__title">Planes</h2>
          <div class="plans__item">
            <header>Plan <b>Startup</b></header>
            <main>
              <p>$1500 <small>USD</small> <span>POR PROYECTO</span></p>
              <ul>
                <li>
                  <img src="../../assets/icons/icon-check.svg" alt="icon check" title="icon check">
                  10 sessiones de 1 hora para entender el contexto.
                </li>
                <li>
                  <img src="../../assets/icons/icon-check.svg" alt="icon check" title="icon check">
                  Atenci&oacute;n directa del consultor por WhatsApp en horario de oficina.
                </li>
                <li>
                  <img src="../../assets/icons/icon-check.svg" alt="icon check" title="icon check">
                  Asignaci&oacute;n de consultor con m&aacute;s experiencia en la tem&aacute;tica.
                </li>
                <li>
                  <img src="../../assets/icons/icon-check.svg" alt="icon check" title="icon check">
                  3 reuniones de presentaci&oacute;n de avance.
                </li>
                <li>
                  <img src="../../assets/icons/icon-check.svg" alt="icon check" title="icon check">
                  Plataforma web para seguimiento a la formulaci&oacute;n.
                </li>
              </ul>
              <a class="plans__button"  href="#appointment" onclick="Calendly.initPopupWidget({url: 'https://calendly.com/wydealab/innovation'});return false;">
                Agendar con Experto
              </a>
              <small>El agendamiento de citas est&aacute; con el horario de <b>Colombia UTC -5</b>.</small>
            </main>
          </div>
        </b-col>
        <b-col cols="12" md=""></b-col>
      </b-row>
    </b-container>
  </section>
</template>

<script>
export default {
  name: 'plans'
}
</script>

<style lang="scss">
.plans {
  padding: 50px 0;
  width: 100%;
  background-color: #F5F9FF;

  &__title {
    margin: 0 0 40px;
    font-family: 'Quicksand', sans-serif;;
    font-size: 48px;
    font-weight: 600;
    color: #171E44;
    text-align: center;
  }

  &__item {
    border-radius: 10px;
    overflow: hidden;

    header {
      padding: 20px;
      background-color: #21A1FF;
      font-family: 'Quicksand', sans-serif;;
      font-size: 18px;
      font-weight: bold;
      color: white;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    main {
      padding: 30px 40px;
      background-color: white;
      box-shadow: 0px 6px 30px #9BA5BD5D;

      p {
        margin: 0 0 30px;
        font-family: 'Quicksand', sans-serif;;
        font-size: 36px;
        font-weight: bold;
        color: #006DF0;
        text-align: center;

        span {
          font-family: 'Mulish', sans-serif;;
          font-size: 14px;
          font-weight: 500;
          color: #171E44;
        }
      }

      ul {
        margin: 0 0 40px;
        padding: 0;
        list-style: none;

        li {
          margin: 0 0 15px;
          font-family: 'Mulish', sans-serif;;
          font-size: 18px;
          font-weight: 500;
          color: #171E44;
          display: flex;
          align-items: center;

          img {
            margin: 0 20px 0 0;
          }
        }
      }

      & > span {
        margin: 10px 0 0;
        font-family: 'Mulish', sans-serif;;
        font-size: 12px;
        font-weight: normal;
        color: #171E44;
        text-align: center;
        display: block;
      }
    }
  }

  &__button {
    width: auto;
    height: 40px;
    margin: 0;
    padding: 0 10px;
    border: 2px solid #171E44;
    border-radius: 32px;
    background-color: #FFFE60;
    font-family: 'Quicksand', sans-serif;;
    font-size: 16px;
    font-weight: 500;
    color: #171E44;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  // responsive para dispositivos de tablets en adelante
  @media (min-width: 992px) {
    padding: 90px 0 110px;

    &__button {
      max-width: 200px;
      margin: 0 auto;
    }
  }
}
</style>
