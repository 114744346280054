<template>
  <section class="dream" id="what">
    <b-container fluid="lg">
      <b-row>
        <b-col cols="12" md="6">
          <div class="dream__img">
            <img
              v-if="item == 0"
              src="../../assets/images/img-wireframe-1.png"
              alt="image wireframe"
              title="image wireframe"
            />
            <img
              v-else-if="item == 1"
              src="../../assets/images/img-wireframe-2.png"
              alt="image wireframe"
              title="image wireframe"
            />
            <img
              v-else-if="item == 2"
              src="../../assets/images/img-wireframe-3.png"
              alt="image wireframe"
              title="image wireframe"
            />
          </div>
          <div class="dream__options">
            <button :class="item == 0 ? 'active' : ''" v-on:click="changeOption(0)"></button>
            <button :class="item == 1 ? 'active' : ''" v-on:click="changeOption(1)"></button>
            <button :class="item == 2 ? 'active' : ''" v-on:click="changeOption(2)"></button>
          </div>
        </b-col>
        <b-col cols="12" md="6">
          <div class="dream__content">
            <div>
              <h2 class="dream__title">¿Qué es?</h2>
              <p class="dream__paragraph">
                <strong>WydeaLab</strong> es una plataforma que permite madurar y soportar ideas para transformarlas
                en negocios. Permite gestionar problemáticas, capacidades y oportunidades para darle vida a un
                producto, servicio o empresa.
              </p>
              <p class="dream__paragraph">
                <b>WydeaLab</b> simplifica el día a día cuando estas innovando, dejando todo centralizado en un solo
                lugar para aplicar herramientas, metodologías y técnicas que generen resultados disruptivos.
              </p>
              <p class="dream__paragraph">
                Es el resultado de años de consultoría de innovación, de sugerencia y errores, es el consolidado
                de experiencias de varios expertos en innovación. Seguimos evolucionando y podrás opinar para
                ayudarnos a mejorar.
              </p>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </section>
</template>

<script>
export default {
  name: "dream",
  data: function () {
    return {
      item: 1,
    };
  },
  mounted() {
    this.autoChangeItem();
  },
  methods: {
    autoChangeItem: function () {
      setTimeout(() => {
        if (this.item == 2) {
          let item = 0;
          this.item = item;
          this.autoChangeItem();
        } else {
          const item = this.item + 1;
          this.item = item;
          this.autoChangeItem();
        }
      }, 3000);
    },

    changeOption: function (option) {
      this.item = option;
    },
  },
};
</script>

<style lang="scss">
.dream {
  padding: 50px 0;
  width: 100%;
  background-color: #171e44;

  &__img {
    margin: 0 0 45px;

    img {
      width: 100%;
      display: block;
    }
  }

  &__options {
    button {
      width: 9px;
      min-width: 9px;
      height: 9px;
      padding: 0;
      margin: 0 5px;
      border: 0;
      border-radius: 50%;
      background-color: #384C74;

      &.active {
        background-color: #FFFE60;
      }
    }
  }

  &__title {
    margin: 0 0 30px;
    font-family: "Quicksand", sans-serif;
    font-size: 30px;
    font-weight: 500;
    color: #21a1ff;
    text-align: center;
  }

  &__paragraph {
    margin: 0 0 30px;
    font-family: "Mulish", sans-serif;
    font-size: 18px;
    font-weight: 500;
    color: white;
    text-align: justify;
  }

  // responsive para dispositivos de tablets en adelante
  @media (min-width: 992px) {
    padding: 80px 0;

    &__title,
    &__paragraph {
      text-align: left;
    }

    &__content {
      padding: 20px 0;
    }
  }
}
</style>
