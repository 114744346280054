<template>
  <section class="plans bg-warning" id="plans">
    <b-container>
      <b-row>
        <b-col cols="12" md=""></b-col>
        <b-col cols="12" md="6">
          <h2 class="plans__title">Empezar, tan sencillo como dar un click</h2>
          <div class="plans__item">
            <header>Plan Starter</header>
            <main>
              <p>
                $30 <small>USD</small> <span>POR MES</span><br>
                <small style="font-size: 0.7em">Pago anualmente.</small>
              </p>

              <ul>
                <li>
                  <img src="../../assets/icons/icon-check.svg" alt="icon check" title="icon check">
                  Documentación de apoyo.
                </li>
                <li>
                  <img src="../../assets/icons/icon-check.svg" alt="icon check" title="icon check">
                  Se incluyen &nbsp;<strong>todos los módulos</strong>.</li>
                <li>
                  <img src="../../assets/icons/icon-check.svg" alt="icon check" title="icon check">
                  Platforma 100% en la Nube de &nbsp;<b>Amazon AWS</b>.</li>
                <li>
                  <img src="../../assets/icons/icon-check.svg" alt="icon check" title="icon check">
                  <strong>Soporte y actualizaciones</strong>&nbsp; incluidas.</li>
              </ul>
              <a class="button_secondary" href="https://scrapidt.wydealab.com/payments">Empezar</a>
              <span style="font-size: 1.2em">Es demasiado económico para no tenerlo.</span>
            </main>
          </div>
        </b-col>
        <b-col cols="12" md=""></b-col>
      </b-row>
    </b-container>
  </section>
</template>

<script>
export default {
  name: 'plans'
}
</script>

<style lang="scss" scoped>
.plans {
  padding: 50px 0;
  width: 100%;
  background-color: #ffc107;

  &__title {
    margin: 0 0 40px;
    font-family: 'Quicksand', sans-serif;;
    font-size: 48px;
    font-weight: 600;
    color: #171E44;
    text-align: center;
  }

  &__item {
    border-radius: 10px;
    overflow: hidden;

    header {
      padding: 20px;
      background-color: #21A1FF;
      font-family: 'Quicksand', sans-serif;;
      font-size: 18px;
      font-weight: bold;
      color: white;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    main {
      padding: 30px 40px;
      background-color: white;
      box-shadow: 0px 6px 30px #9BA5BD5D;

      p {
        margin: 0 0 30px;
        font-family: 'Quicksand', sans-serif;;
        font-size: 36px;
        font-weight: bold;
        color: #006DF0;
        text-align: center;

        span {
          font-family: 'Mulish', sans-serif;;
          font-size: 14px;
          font-weight: 500;
          color: #171E44;
        }
      }

      ul {
        margin: 0 0 40px;
        padding: 0;
        list-style: none;

        li {
          margin: 0 0 15px;
          font-family: 'Mulish', sans-serif;;
          font-size: 18px;
          font-weight: 500;
          color: #171E44;
          display: flex;
          align-items: center;

          img {
            margin: 0 20px 0 0;
          }
        }
      }

      & > span {
        margin: 10px 0 0;
        font-family: 'Mulish', sans-serif;;
        font-size: 12px;
        font-weight: normal;
        color: #171E44;
        text-align: center;
        display: block;
      }
    }
  }
  // responsive para dispositivos de tablets en adelante
  @media (min-width: 992px) {
    padding: 90px 0 110px;

    &__button {
      max-width: 140px;
      margin: 0 auto;
    }
  }
}
</style>
