import Vue from 'vue';
import VueRouter from 'vue-router';
import legal from '../views/info/legal.vue';
import terms from '../views/info/terms.vue';
import confidence from '../views/info/confidence.vue';
import articles from '../views/landing/articles.vue';
import scrapidt from '../views/ScrapidView.vue';
import wydealab from '../views/WydeaLab.vue';
import formulation from '../views/FormulationView.vue';

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: wydealab
  },
  {
    path: '/home',
    redirect: '/'
  },
  {
    path: '/privacy_policy',
    name: 'legal',
    component: legal
  },
  {
    path: '/terms_of_use',
    name: 'terms',
    component: terms
  },
  {
    path: '/data_treatment',
    name: 'confidence',
    component: confidence
  },
  {
    path: '/scrapidt',
    name: 'scrapidt',
    component: scrapidt,
  },
  {
    path: '/formulation',
    name: 'formulation',
    component: formulation
  },
  {
    path: '/articles',
    name: 'articles',
    component: articles
  }
]
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
export default router;
