<template>
  <section>
    <div class="banner__footer">
      <img
          src="../../assets/icons/icon-list.svg"
          alt="icon lab"
          title="icon lab"
          height="40px"
      />
      <p>
        Vivimos para acercarte a cada oportunidad que un emprendedor
        debería tener para potenciar su negocio.
      </p>
    </div>
  </section>
</template>

<script>
export default {
  name: "note",
};
</script>