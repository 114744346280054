<template>
  <section>
    <b-nav class="navProduct" align="center" pills>
      <b-nav-item class="item-nav" href="#what">¿Qué es?</b-nav-item>
      <b-nav-item class="item-nav"  href="#how">¿Cómo funciona?</b-nav-item>
      <b-nav-item class="item-nav"  href="#modules">¿Qué módulos tiene?</b-nav-item>
      <b-nav-item class="item-nav" href="#plans" id="how-much">¿Cuánto cuesta?</b-nav-item>
      <!--<b-nav-item class="item-nav">¿Quién lo utiliza?</b-nav-item>-->
      <b-nav-item class="item-nav" onclick="Calendly.initPopupWidget({url: 'https://calendly.com/wydealab/innovation'});return false;">Solicitar asesoría</b-nav-item>
      <!--<a class="button_secondary mr-1" style="min-width:10em;" href="#scheduler" onclick="Calendly.initPopupWidget({url: 'https://calendly.com/wydealab/innovation'});return false;" rel="noopener noreferrer">Solicitar Reunión</a>-->
    </b-nav>
    <b-tooltip target="how-much" triggers="hover">
      Créeme, es muy barato, da click para sorprenderte.
    </b-tooltip>
  </section>
</template>

<script>
export default {
  name: "navProduct",
};
</script>
<style lang="scss" scoped>
  .navProduct{
    background-color:#1b2c66;
  }
  .item-nav{
    a{
      color: #fefefe;
      font-size: 1.2em;
    }
    a:hover{
      color: #21A1FF;
    }
  }
</style>