<template>
  <section class="methodology" id="how">
    <b-container fluid="lg">
      <b-row>
        <b-col>
          <h2 class="methodology__title">¿Cu&aacute;l es la metodolog&iacute;a?</h2>
          <p class="methodology__paragraph">
            Te explicamos de manera sencilla lo que hacemos para formular proyectos de calidad que generen impacto en tu negocio.
          </p>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" md="6">
          <div class="methodology__item">
            <h3>
              <img
                src="../../assets/icons/icon-number-1.svg"
                alt="icon number 1"
                title="icon number 1"
              />
              Realizar un diagn&oacute;stico de la situaci&oacute;n actual.
            </h3>
            <section>
              <img
                src="../../assets/icons/icon-metodologia-3.svg"
                alt="icon methodology 1"
                title="icon methodology 1"
              />
              <p>
                Iniciamos conociendo el negocio, sus problem&aacute;ticas, fallas, fracasos, motivaciones, beneficios
                y lo que nos permita para apropiar el reto del proyecto.
              </p>
            </section>
          </div>
        </b-col>
        <b-col cols="12" md="6">
          <div class="methodology__img">
            <img
              src="../../assets/images/formulation/1.png"
              alt="images methodology 1"
              title="images methodology 1"
            />
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" md="6" order="2" order-md="1">
          <div class="methodology__img">
            <img
              src="../../assets/images/formulation/2.png"
              alt="images methodology 2"
              title="images methodology 2"
            />
          </div>
        </b-col>
        <b-col cols="12" md="6" order="1" order-md="2">
          <div class="methodology__item">
            <h3>
              <img
                src="../../assets/icons/icon-number-2.svg"
                alt="icon number 2"
                title="icon number 2"
              />
              Perfilar y madurar las ideas base.
            </h3>
            <section>
              <img
                src="../../assets/icons/icon-metodologia-1.svg"
                alt="icon methodology 2"
                title="icon methodology 2"
              />
              <p>
                Escuchar y documentar el sueño, y las expectativas para sintetizar y madurar una idea que permita
                enfocar el proyecto y establecer acciones concretas.
              </p>
            </section>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" md="6">
          <div class="methodology__item">
            <h3>
              <img
                src="../../assets/icons/icon-number-3.svg"
                alt="icon number 3"
                title="icon number 3"
              />
              Delimitar el proyecto.
            </h3>
            <section>
              <img
                src="../../assets/icons/icon-metodologia-4.svg"
                alt="icon methodology 3"
                title="icon methodology 3"
              />
              <p>
                Sintetizar lo que se requiere, identificando las 10 caracteristicas prinncipales, ventajas esperadas y
                beneficios para fijar un norte claro y de la misma manera acotar el proyecto.
              </p>
            </section>
          </div>
        </b-col>
        <b-col cols="12" md="6">
          <div class="methodology__img">
            <img
              src="../../assets/images/formulation/3.png"
              alt="images methodology 3"
              title="images methodology 3"
            />
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" md="6" order="2" order-md="1">
          <div class="methodology__img">
            <img
              src="../../assets/images/formulation/4.png"
              alt="images methodology 4"
              title="images methodology 4"
            />
          </div>
        </b-col>
        <b-col cols="12" md="6" order="1" order-md="2">
          <div class="methodology__item">
            <h3>
              <img
                src="../../assets/icons/icon-number-4.svg"
                alt="icon number 4"
                title="icon number 4"
              />
              Investigar el estado del arte y los recursos claves.
            </h3>
            <section>
              <img
                src="../../assets/icons/icon-lab-white.svg"
                alt="icon methodology 4"
                title="icon methodology 4"
              />
              <p>
                Al entender el proyecto podremos investigar tomando fuentes de referencia de indole cientifico y
                comercial para identificar riesgos, fortalezas y debilidades para asi plantear acciones para transformarlos
                en oportunidades. Proponer recursos claves de índole público ó privados para simplificar el desarrollo del proyecto.
              </p>
            </section>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" md="6">
          <div class="methodology__item">
            <h3>
              <img
                src="../../assets/icons/icon-number-5.svg"
                alt="icon number 5"
                title="icon number 5"
              />
              Elaboraci&oacute;n final de la formulaci&oacute;n
            </h3>
            <section>
              <img
                src="../../assets/icons/icon-metodologia-2.svg"
                alt="icon methodology 5"
                title="icon methodology 5"
              />
              <p>
                Consolidar un plan con alcance, tiempo, costo u dem&aacute;s informaci&oacute;n relevante para estructurar
                metodológica, económica y técnicamente la propuesta contenido. Y realizar al final una sesi&oacute;n
                conjunta de validaci&oacute;n que permita generar el proyecto final.
              </p>
            </section>
          </div>
        </b-col>
        <b-col cols="12" md="6">
          <div class="methodology__img m-0">
            <img
              src="../../assets/images/formulation/5.png"
              alt="images methodology 5"
              title="images methodology 5"
            />
          </div>
        </b-col>
      </b-row>
    </b-container>
  </section>
</template>

<script>
export default {
  name: 'methodology'
}
</script>

<style lang="scss">
.methodology {
  padding: 50px 0;
  width: 100%;
  background-color: #fefefe;

  &__title {
    margin: 0 0 30px;
    font-family: "Quicksand", sans-serif;
    font-size: 48px;
    font-weight: 500;
    color: #21a1ff;
    text-align: center;
  }

  &__paragraph {
    margin: 0 0 30px;
    font-family: "Mulish", sans-serif;
    font-size: 24px;
    font-weight: 500;
    color: #171e44;
    text-align: center;
  }

  &__item {
    h3 {
      margin: 0 0 30px;
      font-family: "Quicksand", sans-serif;
      font-size: 36px;
      font-weight: 500;
      color: #171e44;
      display: flex;
      align-items: center;

      img {
        margin: 0 20px 0 0;
        display: block;
      }
    }

    section {
      width: 100%;
      padding: 30px;
      border-radius: 10px;
      background-color: #1b2c66;

      img {
        margin: 0 auto 30px;
        display: block;
      }

      p {
        margin: 0 0 30px;
        font-family: "Mulish", sans-serif;
        font-size: 20px;
        font-weight: 500;
        color: white;
        text-align: center;
      }
    }
  }

  &__img {
    margin: 50px 0 100px;

    img {
      width: 100%;
      display: block;
    }
  }

  // responsive para dispositivos de tablets en adelante
  @media (min-width: 992px) {
    padding: 80px 0;

    &__paragraph {
      max-width: 590px;
      margin: 0 auto 90px;
    }

    &__img {
      margin: 0 0 100px;

      img {
        width: auto;
        height: 375px;
      }
    }
  }
}
</style>
