<template>
  <section class="start">
    <b-container>
      <b-row>
        <b-col cols="12" md=""></b-col>
        <b-col class="text-center align-items-center" cols="12" md="8">
          <h2 class="start__title">
            Tengo una idea que quiero convertir en mi motor económico.
          </h2>
          <div class="text-center">
            <a class="button_secondary" href="/signup/">Iniciar</a>
          </div>
        </b-col>
        <b-col cols="12" md=""></b-col>
      </b-row>
    </b-container>
  </section>
</template>

<script>
export default {
  name: "start",
};
</script>

<style lang="scss">
.start {
  padding: 50px 0;
  width: 100%;
  background-color: #171e44;

  &__title {
    margin: 0 0 40px;
    font-family: "Quicksand", sans-serif;
    font-size: 36px;
    font-weight: bold;
    color: white;
    text-align: center;
  }

  // responsive para dispositivos de tablets en adelante
  @media (min-width: 992px) {
    padding: 90px 0 110px;

    &__button {
      max-width: 140px;
      margin: 0 auto;
    }
  }
}
</style>
