<template>
  <main>
    <headerCustom :menuActive="true" />
    <section class="content">
      <b-container fluid="lg">
        <b-row>
          <b-col cols="12" md="4" order-md="2">
            <nav class="content__nav">
              <a class="active" href="/privacy_policy/">Política de Privacidad</a>
              <a href="/terms_of_use/">Términos de Servicio</a>
              <a href="/data_treatment/">Política de Tratamiento de Datos Personales</a>
            </nav>
          </b-col>
          <b-col cols="12" md="8" order-md="1">
            <h2 class="content__title">Política de Privacidad</h2>
            <p class="content__paragraph">
              Acontinuación se presentan nuestros Téminos de Servicio, los
              cuales establecen los acuerdos legales relacionados con nuestros
              productos y servicios, estos se deben respetar sin importar si
              eres nuestro cliente, socio, inversionista o proveedor.
            </p>
            <p class="content__paragraph">
              <strong>Aceptación de los términos del contrato</strong><br />
              Lo siguiente son los términos de un convenio legal celebrado entre
              tú y Wydea. Al accesar, hojear y/o usar nuestros productos y
              servicios admites haber leído y entendido estos términos y estas
              de acuerdo en sujetarte a ellos y cumplir con todas las leyes y
              reglamentos aplicables. En caso de que no estés de acuerdo con
              estos términos, por favor no uses nuestros productos o servicios.
              El material suministrado en este sitio está protegido por la ley,
              incluyendo, más no limitado a, las leyes sobre derechos de autor
              de Colombia. Este sitio es controlado y operado por Wydea desde
              sus oficinas ubicadas en Colombia. Wydea no se responsabiliza de
              que el material en nuestros productos o servicios sea apropiado o
              esté disponible para su uso en otros lugares, estando prohibido su
              acceso desde territorios donde su contenido sea ilegal. Aquellos
              que decidan accesar este sitio desde otros lugares lo harán bajo
              su propia iniciativa y es tu responsabilidad sujetarte a las leyes
              locales que sean aplicables. Cualquier reclamo con relación a, y
              al uso de nuestros productos o servicios y el material en ellos
              contenido está regulado por las leyes colombianas
            </p>
            <p class="content__paragraph">
              <strong>Restricciones de uso</strong><br />
              Los derechos de autor de todo el material suministrado en este
              sitio son propiedad de Wydea. Exceptuando lo expresamente
              estipulado en este documento, nada de este material puede ser
              copiado, reproducido, distribuido, vuelto a publicar, desplegado,
              anunciado o transmitido de ninguna manera o por ningún medio,
              incluyendo, más no limitado a, medios electrónicos, mecánicos, de
              fotocopiado, de grabación o de cualquier otra índole, sin el
              permiso previo por escrito de SYSTEMICO. Se otorga permiso para
              desplegar en pantalla, copiar y distribuir el material de nuestros
              productos o servicios únicamente para uso personal no comercial,
              con la condición de que no modifiques el material y que conserves
              todas las leyendas de derechos de autor y de otro tipo de
              propiedad contenidas en este material. Este permiso termina
              automáticamente si violas cualquiera de estos términos y
              condiciones. A tu terminación, destruirás de manera inmediata
              cualquier material que hayas reproducido o impreso. Tampoco
              puedes, sin el permiso de Wydea, hacer réplicas en otro servidor
              de cualquier material contenido en este sitio. Cualquier uso no
              autorizado de cualquier material contenido en nuestros productos o
              servicios puede constituir una violación de las leyes de derechos
              de autor, de las leyes de marcas comerciales, de las leyes de
              privacidad y publicidad y de las leyes y los reglamentos de
              comunicaciones.
            </p>
            <p class="content__paragraph">
              <strong>Marcas Comerciales</strong><br />
              Las marcas comerciales, las marcas de productos y servicio,
              eslogans y los logos usados y desplegados en nuestros productos o
              servicios son Marcas Comerciales registradas y no registradas de
              Wydea y otros. Wydea hace cumplir de manera enérgica sus derechos
              de propiedad intelectual en toda la extensión de la ley. El nombre
              de Wydea o los logos propiedad de Wydea no puede ser usados de
              manera alguna, incluyendo anuncios o publicidad relacionados con
              la distribución del material en nuestros productos o servicios,
              sin permiso previo por escrito. Wydea prohíbe el uso de los logos
              propiedad de Wydea como un enlace a cualquiera de los sitios de
              Wydea, a menos que el establecimiento de un enlace de tal
              naturaleza sea aprobado por Wydea previamente por escrito.
            </p>
            <p class="content__paragraph">
              <strong>Desconocimiento de la garantía</strong><br />
              Wydea no garantiza ni hace representación alguna en relación al
              uso, validez, precisión o confiabilidad de, o los resultados del
              uso de, o cualquier otra situación al respecto de, el material en
              nuestros productos o servicios o cualquier producto o servicio
              enlazado con nuestros productos o servicios.
            </p>
            <p class="content__paragraph">
              <strong>Responsabilidad Limitada</strong><br />
              En ninguna circunstancia, incluyendo, pero no limitada a,
              negligencia, será Wydea responsable por cualquier daño directo,
              indirecto, especial, incidental, consecuente, incluyendo, mas no
              limitado a, pérdida de información o utilidades, resultado del
              uso, o la incapacidad para usar el material en nuestros productos
              o servicios, aún en el caso de que Wydea o un representante
              autorizado de Wydea haya sido puesto al tanto de la posibilidad de
              tal daño. En caso de que tu uso del material de nuestros productos
              o servicios de como resultado la necesidad de dar servicio a,
              reparar o corregir equipo o información, asumes cualquier costo
              derivado por ello. Algunos países no permiten la exclusión o
              limitación de daños incidentales o consecuentes, así es que las
              limitaciones o exclusiones arriba mencionadas pueden no aplicar en
              tu caso.
            </p>
            <p class="content__paragraph">
              <strong> Nuestras transmisiones</strong><br />
              Se prohíbe poner en, o transmitir a, o desde nuestros productos o
              servicios cualquier material ilegal, amenazador, calumniador,
              difamatorio, obsceno, escandaloso, pornográfico o profano, o
              cualquier otro material que pudiera dar lugar a cualquier
              responsabilidad civil o penal en los términos de la ley.
            </p>
            <p class="content__paragraph"><strong></strong><br /></p>
            <p class="content__paragraph">
              <strong>Revisión de los Términos del Servicio</strong><br />
              Wydea puede en cualquier momento revisar estos Términos de
              Servicio por medio de la actualización de este anuncio. Al usar
              nuestros productos o servicios, tu convienes en darte por obligado
              por cualquiera de tales revisiones, debiendo entonces visitar
              periódicamente esta página para determinar los términos de uso
              vigentes en ese momento a los cuales estás obligado.
            </p>
          </b-col>
        </b-row>
      </b-container>
    </section>
    <footerCustom />
  </main>
</template>

<script>
import headerCustom from "../../components/basics/headerCustom.vue";
import footerCustom from "../../components/basics/footerCustom.vue";

export default {
  name: "legal",
  components: {
    headerCustom,
    footerCustom,
  },
};
</script>

<style lang="scss">
.content {
  padding: 80px 0 0;

  &__title {
    margin: 0 0 30px;
    font-family: "Quicksand", sans-serif;
    font-size: 30px;
    font-weight: bold;
    color: #171e44;
    text-align: center;
  }

  &__paragraph {
    margin: 0 0 30px;
    font-family: "Mulish", sans-serif;
    font-size: 18px;
    font-weight: 500;
    color: #171e44;
    text-align: justify;
  }

  &__nav {
    width: 100%;
    margin: 0 0 30px;
    padding: 30px;
    border-radius: 10px;
    background-color: #f5f9ff;

    a {
      width: 100%;
      min-height: 40px;
      margin: 0 0 10px;
      padding: 10px 20px;
      border: 0;
      border-radius: 32px;
      background: transparent;
      font-family: "Quicksand", sans-serif;
      font-size: 16px;
      font-weight: 500;
      color: #006df0;
      text-align: left;
      display: flex;
      align-items: center;

      &:hover,&.active {
        background-color: white;
        text-decoration: none;
      }
    }
  }

  // responsive para dispositivos de tablets en adelante
  @media (min-width: 992px) {
    &__nav {
      margin: 0;
      position: sticky;
      top: 150px;
    }
  }
}
</style>