<template>
  <main>
    <headerCustom :menuActive="true"/>
    <banner />
    <dream />
    <methodology />
    <benefits />
    <testimonials />
    <start />
    <plans />
    <about />
    <questions />
    <footerCustom />
  </main>
</template>

<script>
import headerCustom from '../../components/basics/headerCustom.vue'
import banner from '../../components/articles/banner.vue'
import dream from '../../components/landing/dream.vue'
import methodology from '../../components/landing/methodology.vue'
import benefits from '../../components/landing/benefits.vue'
import testimonials from '../../components/landing/testimonials.vue'
import start from '../../components/landing/start.vue'
import plans from '../../components/landing/plans.vue'
import about from '../../components/landing/about.vue'
import questions from '../../components/landing/questions.vue'
import footerCustom from '../../components/basics/footerCustom.vue'

export default {
  name: 'home',
  components: {
    headerCustom,
    banner,
    dream,
    methodology,
    benefits,
    testimonials,
    start,
    plans,
    about,
    questions,
    footerCustom
  } 
}
</script>