
<template>
  <section class="about" id="about">
    <b-container>
      <b-row>
        <b-col>
          <h2 class="about__title">Conoce más sobre nosotros.</h2>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" md="6">
          <div class="about__logo">
            <img src="../../assets/logos/logo-color.svg" alt="logo wydealab" title="logo wydealab">
          </div>
          <div class="about__contact">
            <p class="about__paragraph">En caso de tener dudas puedes comunicarte con nosotros y solicitar información adicional.</p>
            <a href="mailto:hello@wydealab.com">
              <img src="../../assets/icons/icon-email.svg" alt="icon email" title="icon email">
              hello@wydealab.com
            </a>
          </div>
        </b-col>
        <b-col cols="12" md="6">
          <p class="about__paragraph">
            <strong>WydeaLab</strong> es una unidad de I+D+i enfocada en suministrar servicios
            masivos para la gestión apropiada de la innovación. Fue creada en el
            2021 con la finalidad de apoyar a las empresas que por motivos de la
            pandemia COVID-19 se vieron afectados y tuvieron que reinventarse y
            requerían de apoyo para hacerlo de manera sostenible.
          </p>
          <p class="about__paragraph">
            <strong>WydeaLab</strong> suministra una plataforma completa que integra diferentes
            metodologías, técnicas y experiencias, a partir de las
            recomendaciones de consultores con más de 20 años realizando
            procesos de gestión de innovación y consultoría en tecnología e
            innovación.
          </p>
          <p class="about__paragraph">
            <strong>WydeaLab</strong> está registrada en Colombia como Wydea S.A.S y cuenta con un registro
            como grupo de investigación ante el Ministerio de Ciencia y
            Tecnología e Innovación.
          </p>
          <p class="about__paragraph">
            La meta principal de <strong>WydeaLab</strong> es permitir a los emprendedores,
            empresas pequeñas y corporativos tener un modelo claro de innovación
            que permita integrar a sus equipos en una cultura permanente de
            innovación.
          </p>
        </b-col>
      </b-row>
    </b-container>
  </section>
</template>

<script>
export default {
  name: "about",
};
</script>

<style lang="scss">
.about {
  padding: 50px 0;
  width: 100%;
  background-color: white;

  &__title {
    margin: 0 0 70px;
    font-family: "Quicksand", sans-serif;
    font-size: 36px;
    font-weight: 600;
    color: #171e44;
    text-align: center;
  }

  &__paragraph {
    margin: 0 0 30px;
    font-family: "Quicksand", sans-serif;
    font-size: 18px;
    font-weight: 500;
    color: #171E44;
  }

  &__logo {
    padding: 40px;
    border-radius: 10px;
    background-color: #FFFE60;

    img {
      width: 100%;
      display: block;
    }
  }

  &__contact {
    margin: 80px 0;
    padding: 40px;
    border-radius: 10px;
    background-color: #F5F9FF;

    a {
      margin: 0;
      font-family: "Quicksand", sans-serif;
      font-size: 16px;
      font-weight: normal;
      color: #006DF0;
      display: flex;
      align-items: center;

      img {
        margin: 0 10px 0 0;
      }
    }
  }

  // responsive para dispositivos de tablets en adelante
  @media (min-width: 992px) {
    padding: 90px 0 110px;

    &__logo{
      padding: 100px 70px;
      margin: 0 0 80px;
    }

    &__contact {
      margin: 0;
    }
  }
}
</style>
