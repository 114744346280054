<template>
  <section class="banner bg-warning" >
    <b-container fluid="lg" >
      <b-row>
        <b-col cols="12" md="6">
          <div class="banner__img d-sm-none d-md-block d-none d-sm-block">
            <img id="item-banner-1"
              src="@/assets/images/formulation/header.png"
              alt="image banner"
              title="image banner"
            />
          </div>
        </b-col>
        <b-col cols="12" md="6">
          <div class="banner__content">
            <div>
              <h2 class="banner__title">
                <b>Formulamos proyectos que generan impacto y crecimiento en tu negocio.</b></h2>
              <p class="banner__paragraph">
                <strong>Los proyectos </strong> son la forma de mantener avanzando tu organizaci&oacute;n.
                <b><a id="item-banner-2" class="text-dark" href="#proyectos" style="text-decoration: underline;">Nos dedicamos a formular proyectos</a> de innovacion, tecnolog&iacute;a, agro, entre otros</b>.
                <div class="btn-group" role="group" aria-label="">
                  <a class="button_secondary mr-1 rounded-0 font-weight-bold pl-5 pr-5" href="#appointment" onclick="Calendly.initPopupWidget({url: 'https://calendly.com/wydealab/innovation'});return false;" rel="noopener noreferrer">Me interesa</a>
                </div>
                <div>
                  <small>Contamos con un <b>equipo experto en proyectos, tecnolog&iacute;a, <br />negocios y procesos</b> para crear algo genial.</small>
                </div>
              </p>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-container>


    <b-tooltip target="item-banner-1" triggers="hover">
      <b>Formular proyectos</b> es el arte de planear lo que sueñas mitigando los riesgos y disminuyendo la incertidumbre.
    </b-tooltip>
    <b-tooltip target="item-banner-2" triggers="hover">
      Identificar el status-quo, problem&aacute;ticas, oportunidades y dar el fundamento apropiado a tu
      proyecto <b>disminuye riesgos al invertir los recursos de tu organizaci&oacute;n, incrementando tu factor de &eacute;xito</b>.
    </b-tooltip>
  </section>
</template>

<script>
export default {
  name: 'banner'
}
</script>

<style lang="scss">
.banner {
  padding: 30px 0 30px 0;
  width: 100%;

  &__img {
    margin: 0 0 25px;

    img {
      width: 100%;
      display: block;
    }
  }

  &__title {
    margin: 0 0 10px;
    font-family: "Quicksand", sans-serif;
    font-size: 40px;
    color: #171e44;
    text-align: center;
  }

  &__paragraph {
    margin: 0 0 30px;
    font-family: "Mulish", sans-serif;
    font-size: 18px;
    font-weight: 500;
    color: #171e44;
    text-align: center;
  }

  &__button {
    width: 100%;
    height: 40px;
    margin: 0x;
    padding: 0 10px;
    border: 2px solid #333333;
    border-radius: 32px;
    background-color: #fffe60;
    font-family: "Quicksand", sans-serif;
    font-size: 16px;
    font-weight: 500;
    color: #171e44;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &__button:hover {
    background-color: #006df0;
    border: 2px solid #006df0;
    color: #fefefe;
  }

  &__footer {
    margin: 30px 0 0;
    padding: 30px 15px;
    background-color: #dddddd;

    img {
      margin: 0 auto 30px;
      display: block;
    }

    p {
      margin: 0;
      font-family: "Mulish", sans-serif;
      font-size: 18px;
      font-weight: 500;
      color: #171e44;
      text-align: center;
    }
  }

  // responsive para dispositivos de tablets en adelante
  @media (min-width: 992px) {
    padding: 30px 0 0;

    &__title,
    &__paragraph {
      text-align: left;
    }

    &__button {
      max-width: 180px;
    }

    &__content {
      height: 100%;
      display: flex;
      align-items: center;
    }

    &__footer {
      margin: 0;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        margin: 0 30px 0 0;
      }

      p {
        max-width: 540px;
        text-align: left;
      }
    }
  }
}
</style>
