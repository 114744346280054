<template>
  <div class="jumichica_widget_whatsapp">
    <div
      style="opacity: 1"
      class="jumichica_widget_whatsapp_text"
      v-for="(item, i) in messages"
      :key="i"
    >
      <div class="jumichica_widget_whatsapp_company_name">
        {{ companyName }}
      </div>
      <div class="jumichica_widget_whatsapp_text">
        {{ item }}
      </div>
      <div class="jumichica_widget_whatsapp_time">
        {{ time }}
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    messages: {
      type: Array,
      required: true,
    },
    companyName: {
      type: String,
      default: "",
    },
  },
  computed: {
    time() {
      return new Date().getHours() + ":" + new Date().getMinutes();
    },
  },
};
</script>
<style scoped>
.jumichica_widget_whatsapp{
  background: #fff;
  padding:5px;
  border-radius: 6px;
  font-size: 14px;
  font-family: Sans-Serif, arial verdana;
}
.jumichica_widget_whatsapp_text{
  font-size:14px;
  color:#444444;
}
.jumichica_widget_whatsapp_company_name{
  font-weight: bold;
  color: #666666;
}
.jumichica_widget_whatsapp_time{
  font-weight: normal;
  color: #666666;
  text-align: right;
}
</style>
