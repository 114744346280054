<template>
  <section class="methodology" id="how">
    <b-container fluid="lg">
      <b-row>
        <b-col>
          <h2 class="methodology__title">¿Cómo funciona?</h2>
          <p class="methodology__paragraph">
            Es simple, solo debes iniciar. Genera una meta clara de innovación, diferenciación y competitividad
            para tu negocio.
          </p>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" md="6">
          <div class="methodology__item">
            <h3>
              <img
                src="../../assets/icons/icon-number-1.svg"
                alt="icon number 1"
                title="icon number 1"
              />
              Ideas claves
            </h3>
            <section>
              <img
                src="../../assets/icons/icon-metodologia-1.svg"
                alt="icon methodology 1"
                title="icon methodology 1"
              />
              <p>
                Genera ideas claras y enfocadas que impacten. Desde la
                ocurrencia hasta la maduración para innovar en el negocio.
              </p>
            </section>
          </div>
        </b-col>
        <b-col cols="12" md="6">
          <div class="methodology__img">
            <img
              src="../../assets/images/img-metodologia-1.jpg"
              alt="images methodology 1"
              title="images methodology 1"
            />
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" md="6" order="2" order-md="1">
          <div class="methodology__img">
            <img
              src="../../assets/images/img-metodologia-2.jpg"
              alt="images methodology 2"
              title="images methodology 2"
            />
          </div>
        </b-col>
        <b-col cols="12" md="6" order="1" order-md="2">
          <div class="methodology__item">
            <h3>
              <img
                src="../../assets/icons/icon-number-2.svg"
                alt="icon number 2"
                title="icon number 2"
              />
              Conócete
            </h3>
            <section>
              <img
                src="../../assets/icons/icon-metodologia-2.svg"
                alt="icon methodology 2"
                title="icon methodology 2"
              />
              <p>
                Realiza identificación de capacidades, gestión de oportunidades
                e identificación de problemáticas.
              </p>
            </section>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" md="6">
          <div class="methodology__item">
            <h3>
              <img
                src="../../assets/icons/icon-number-3.svg"
                alt="icon number 3"
                title="icon number 3"
              />
              Simplifica la visión
            </h3>
            <section>
              <img
                src="../../assets/icons/icon-metodologia-3.svg"
                alt="icon methodology 3"
                title="icon methodology 3"
              />
              <p>
                Establece el norte de tu negocio y compártelo con tu equipo para
                que la visión siempre este presente en cada acción.
              </p>
            </section>
          </div>
        </b-col>
        <b-col cols="12" md="6">
          <div class="methodology__img">
            <img
              src="../../assets/images/img-metodologia-3.jpg"
              alt="images methodology 3"
              title="images methodology 3"
            />
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" md="6" order="2" order-md="1">
          <div class="methodology__img">
            <img
              src="../../assets/images/img-metodologia-4.jpg"
              alt="images methodology 4"
              title="images methodology 4"
            />
          </div>
        </b-col>
        <b-col cols="12" md="6" order="1" order-md="2">
          <div class="methodology__item">
            <h3>
              <img
                src="../../assets/icons/icon-number-4.svg"
                alt="icon number 4"
                title="icon number 4"
              />
              El Negocio
            </h3>
            <section>
              <img
                src="../../assets/icons/icon-metodologia-4.svg"
                alt="icon methodology 4"
                title="icon methodology 4"
              />
              <p>
                Desarrolla modelos de negocio atractivos y viables que generen
                tracción de patrocinadores e inversionistas.
              </p>
            </section>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" md="6">
          <div class="methodology__item">
            <h3>
              <img
                src="../../assets/icons/icon-number-5.svg"
                alt="icon number 5"
                title="icon number 5"
              />
              El plan
            </h3>
            <section>
              <img
                src="../../assets/icons/icon-target.svg"
                alt="icon methodology 5"
                title="icon methodology 5"
              />
              <p>
                Formula proyectos de innovación disruptivos, estructurados y de
                alto impacto para el crecimiento de tu organización.
              </p>
            </section>
          </div>
        </b-col>
        <b-col cols="12" md="6">
          <div class="methodology__img m-0">
            <img
              src="../../assets/images/img-metodologia-5.jpg"
              alt="images methodology 5"
              title="images methodology 5"
            />
          </div>
        </b-col>
      </b-row>
    </b-container>
  </section>
</template>

<script>
export default {
  name: "methodology",
};
</script>

<style lang="scss">
.methodology {
  padding: 50px 0;
  width: 100%;
  background-color: white;

  &__title {
    margin: 0 0 30px;
    font-family: "Quicksand", sans-serif;
    font-size: 48px;
    font-weight: 500;
    color: #21a1ff;
    text-align: center;
  }

  &__paragraph {
    margin: 0 0 30px;
    font-family: "Mulish", sans-serif;
    font-size: 24px;
    font-weight: 500;
    color: #171e44;
    text-align: center;
  }

  &__item {
    h3 {
      margin: 0 0 30px;
      font-family: "Quicksand", sans-serif;
      font-size: 36px;
      font-weight: 500;
      color: #171e44;
      display: flex;
      align-items: center;

      img {
        margin: 0 20px 0 0;
        display: block;
      }
    }

    section {
      width: 100%;
      padding: 30px;
      border-radius: 10px;
      background-color: #21a1ff;

      img {
        margin: 0 auto 30px;
        display: block;
      }

      p {
        margin: 0 0 30px;
        font-family: "Mulish", sans-serif;
        font-size: 20px;
        font-weight: 500;
        color: white;
        text-align: center;
      }
    }
  }

  &__img {
    margin: 30px 0 100px;

    img {
      width: 100%;
      display: block;
    }
  }

  // responsive para dispositivos de tablets en adelante
  @media (min-width: 992px) {
    padding: 80px 0;

    &__paragraph {
      max-width: 590px;
      margin: 0 auto 90px;
    }

    &__img {
      margin: 0 0 100px;

      img {
        width: auto;
        height: 375px;
      }
    }
  }
}
</style>
