
<template>
  <section class="testimonials">
    <b-container>
      <b-row>
        <b-col cols="12" md="4">
          <img class="testimonials__icon" src="../../assets/icons/icon-person.svg" alt="icon person" title="icon person">
          <h3 class="testimonials__title">Testimonios</h3>
        </b-col>
        <b-col cols="12" md="8">
          <div class="testimonials__section">
            <div>
              <p>WydeaLab fue la luz que nos mostró el camino. Queríamos transitar varios caminos al tiempo y
                gracias a acciones semanales pudimos enfocarnos y seguir avanzando, un acierto total.</p>
              <h3>Diego Julian Cardenas</h3>
              <span><a href="https://somosbida.com">somosbida.com</a></span>
            </div>
            <img src="../../assets/icons/icon-cite.svg" alt="icon cite" title="icon cite">
          </div>
        </b-col>
      </b-row>
    </b-container>
  </section>
</template>

<script>
export default {
  name: "testimonials",
};
</script>

<style lang="scss">
.testimonials {
  padding: 50px 0;
  width: 100%;
  background-color: #F5F9FF;

  &__icon {
    margin: 40px auto 15px;
    display: block;
  }

  &__title {
    margin: 0;
    font-family: "Quicksand", sans-serif;
    font-size: 36px;
    font-weight: 600;
    color: #171E44;
    text-align: center;
  }

  &__section {
    margin: 40px 0 0;
    padding: 40px 20px;
    border-radius: 10px;
    background-color: #FFFFFF;
    box-shadow: 0px 6px 30px #9BA5BD5D;

    p {
      margin: 0 0 15px;
      font-family: "Mulish", sans-serif;
      font-size: 16px;
      font-weight: 500;
      color: #171E44;
      text-align: left;
    }
    
    h3 {
      margin: 0 0 5px;
      font-family: "Quicksand", sans-serif;
      font-size: 18px;
      font-weight: 700;
      color: #171E44;
      text-align: left;
    }

    span {
      margin: 0;
      font-family: "Mulish", sans-serif;
      font-size: 16px;
      font-weight: 400;
      color: #006DF0;
      text-align: left;
      display: block;
    }

    img {
      margin: 20px auto;
      display: block;
    }
  }

  // responsive para dispositivos de tablets en adelante
  @media (min-width: 992px) {
    padding: 70px 0;

    &__section {
      margin: 0;
      padding: 40px 60px;
      display: flex;
      align-items: center;

      img {
        margin: 0 40px;
      }
    }
  }
}
</style>
